<template>
  <base-label
    :legend="placeholder ?? ''"
    :labelType="labelType"
    :errors="errors"
    :is-active="isOpen"
    :is-disabled="isDisabled"
    :has-value="!!from"
    :is-loading="isLoading || isInternalLoading"
    :disable-disabled-opacity="disableDisabledOpacity"
    :feedback="feedback"
    class="input-daterange"
    @click="open"
    @keydown.enter="open"
    @focus="onFocus"
    ref="label"
  >
    <div
      class="input-daterange__label"
      @keydown.enter="isOpen = isDisabled ? false : !isOpen"
      @focus="$emit('isFocus', true)"
      @blur="$emit('isFocus', false)"
      ref="input"
      v-class-mod:input-daterange__label="{ isDisabled, isOpen }"
    >
      <span v-if="from" class="input-daterange__text">
        <cut-text
          :text="
            getDuration([from, to ?? from])
              .format(DurationFormat.RANGE)
              ?.toString()
          "
        />
      </span>
      <span v-else class="input-daterange__not-set">
        <cut-text :text="translations.datetime.notSpecified" />
      </span>
      <icon-toggle-button
        v-if="canClear && !isDisabled && !!from"
        :tooltip="{ title: translations.inputs.clear }"
        :action="
          (e) => {
            e.stopPropagation();
            isOpen = false;
            updateValue({ to: undefined, from: undefined });
          }
        "
        icon="close"
        button-size="medium"
        tabindex="0"
        class="input-daterange__clear"
      />
    </div>
    <template v-if="!isDisabled && label?.$el">
      <icon-toggle-button :action="open" button-size="medium" icon="date_range" @click.stop />
      <AppTeleport v-if="isOpen">
        <date-picker
          :to="to"
          :from="from"
          :min="min"
          :max="max"
          :tabindex="-1"
          select="range"
          type="range"
          class="input-daterange__date-picker"
          @update:range="
            (range) => {
              updateValue(range);
              close();
            }
          "
          @close="close"
          ref="picker"
          v-stick-to="{
            align: 'right',
            stick: 'left',
            placement: 'bottom',
            stickTo: label,
            element: picker?.$el,
          }"
          v-lock-focus="true"
          v-click-outside="{
            callback: () => close(),
          }"
        />
      </AppTeleport>
    </template>
  </base-label>
</template>

<script lang="ts" setup>
import { nextTick, ref } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { vClickOutside } from "@horizon56/directives/click-outside";
import { vLockFocus } from "@horizon56/directives/lock-focus";
import { vStickTo } from "@horizon56/directives/stick-to";
import { DateTime, DurationFormat, DurationObject, getDuration } from "@horizon56/time";
import { delayFn } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import CutText from "@/components/content/cut-text.vue";
import DatePicker from "@/components/date-and-time/date-picker/date-picker.vue";
import BaseLabel, { PublicProps } from "@/components/inputs/base-label.vue";
import { FeedbackReturnType } from "@/components/inputs/input-feedback.vue";

const props = withDefaults(
  defineProps<
    PublicProps & {
      placeholder?: string;
      from?: DateTime;
      to?: DateTime;
      max?: DateTime;
      min?: DateTime;
      canClear?: boolean;
      activateOnFocus?: boolean;
      update?: (d: DurationObject) => FeedbackReturnType;
    }
  >(),
  {
    activateOnFocus: true,
  },
);

const emit = defineEmits<{
  (e: "isFocus", a: boolean): void;
  (e: "update", a: DurationObject): void;
}>();

const isOpen = ref(false);
const isInternalLoading = ref(false);
const lockFocus = ref(false);
const label = ref<InstanceType<typeof BaseLabel>>();
const feedback = ref<PublicProps["feedback"]>();

const updateValue = async (u: DurationObject) => {
  emit("update", u);
  if (typeof props.update === "function") {
    isInternalLoading.value = true;
    const result = await props.update(u);
    if (result && "status" in result) {
      feedback.value = {
        ...result,
        callback: () => {
          feedback.value = undefined;
          result.callback?.();
        },
      };
    }
    isInternalLoading.value = false;
  }
};
const input = ref<HTMLDivElement>();
const picker = ref<typeof DatePicker>();

const onFocus = async () => {
  if (props.activateOnFocus && !isOpen.value) {
    await open();
  }
};

const open = async () => {
  if (isOpen.value || props.isDisabled) {
    return;
  }

  window.requestAnimationFrame(async () => {
    isOpen.value = true;
    lockFocus.value = true;
    await delayFn(() => !!picker.value?.$el);
    picker.value?.$el.focus();
  });
};

const close = async () => {
  lockFocus.value = false;
  await nextTick();
  if (shouldFocusOnLabel()) {
    label.value?.$el.focus();
  }
  isOpen.value = false;
};

const shouldFocusOnLabel = () =>
  picker.value?.$el === document.activeElement ||
  picker.value?.$el?.contains(document.activeElement) ||
  !document.contains(document.activeElement);
</script>

<style lang="scss" scoped>
.input-daterange {
  &__label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;
    user-select: none;
    outline: none;
    margin-right: auto;
  }
  &__not-set {
    color: var(--black-50);
  }
  &__date-picker:focus {
    outline: none;
    position: fixed;
  }
  &__not-set,
  &__text {
    margin-right: auto;
  }
  &__clear {
    margin-left: auto;
  }
  &__label:not(#{&}--isDisabled):hover:after {
    color: var(--black-90);
  }
  &:has(#{&}__clear) #{&}__label:after {
    margin-left: 0;
  }
}
</style>
